<template>
  <div class="invoice-actions col-md-4 col-xl-3 col-12">
    <template v-if="selectedAction">

      <b-modal
          id="modal-verify"
          :ok-title="selectedAction.okText"
          cancel-title="cancel"
          centered
          title="Modify Order"
          @ok="sendAction"
      >
        <b-row>
          <b-col v-if="selectedAction.text" cols="12">
            <b-alert class="p-1" show variant="warning">
              <span>{{ selectedAction.text }}</span>
            </b-alert>
          </b-col>
          <b-col v-if="selectedAction.name==='assignBoosters'" cols="12">
            <CustomUserPicker @getUser="setBoosterId"></CustomUserPicker>
          </b-col>
          <b-col v-if="selectedAction.reason" cols="12">
            <b-form-group
                label="reason"
            >
              <b-form-textarea
                  v-model="args.reason"
                  rows="2"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
    </template>
    <div class="card">
      <div class="card-body">
        <b-button v-if="order.orderStatus!=='Waiting' && order.orderStatus!=='Cancel'"
                  aria-controls="sidebar-send-invoice"
                  aria-expanded="false" class="btn mb-75 btn-primary btn-block collapsed"
                  style="overflow-anchor: none;"
                  type="button"
                  variant="primary" @click="$router.push(`/apps/orders/order-chat/${order.orderCode}`)"
        > Chat
        </b-button>
        <b-button v-if="order.boosterId && order.orderStatus==='Done'"
                  v-b-modal.modal-verify
                  aria-controls="sidebar-invoice-add-payment"
                  aria-expanded="false" class="btn mb-75 btn-success btn-block collapsed"
                  style="overflow-anchor: none;"
                  type="button" variant="info"
                  @click="setAction(1)"

        >
          Verify Order
        </b-button>
        <!--        v-if="order.orderStatus==='Payed' || order.orderStatus==='Cancel'"-->
        <b-button
            v-if="order.orderStatus==='Payed' || order.orderStatus==='Cancel'"
            v-b-modal.modal-verify
            class="w-100 mb-75"
            variant="success"
            @click="setAction(2)"
        >
          Assign Boosters
        </b-button>
        <!--        v-if="order.orderStatus==='Doing'"-->
        <b-button
            v-if="order.orderStatus==='Doing'"
            v-b-modal.modal-verify
            class=" w-100  mb-75"
            variant="danger"
            @click="setAction(3)"
        >
          Cancel
        </b-button>
        <b-button
            v-if="order.orderStatus!=='Frozen' &&order.orderStatus!=='VerifyByAdmin' "
            class="w-100 mb-75"
            variant="warning"
            @click="setEdit"
        >
          Edit
        </b-button>
        <b-button
            v-if="order.orderStatus==='Frozen' || order.orderStatus === 'Done'"
            v-b-modal.modal-verify
            class="w-100 mb-75"
            variant="danger"
            @click="setAction(4)"
        >
          Deny
        </b-button>

      </div>
    </div>
  </div>
</template>

<script>
import { BAlert, BButton, BCol, BFormGroup, BFormInput, BFormTextarea, BRow } from 'bootstrap-vue'
import vSelect from 'vue-select'
import CustomUserPicker from '@/views/components/utilities/CustomUserPicker.vue'
import { VerifyOrder } from '@/libs/Api/orderWarrnties'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ChangeOrderBooster, DenyOrder, OrderCancelByAdminRequest } from '@/libs/Api/Order'

export default {
  name: 'OrderInfoSidebar',
  components: {
    CustomUserPicker,
    BFormTextarea,
    BAlert,
    vSelect,
    BButton,
    BCol,
    BRow,
    BFormGroup,
    BFormInput
  },
  props: {
    order: {
      type: Object
    }
  },
  data() {
    return {
      reason: '',
      actionsData: [
        {
          id: 1,
          name: 'verifyOrder',
          text: 'By Verifying, You Are Confirming That Booster Have Done All The Tasks Required And The Profit Will Be Staked At Funds In Hold For Booster',
          notable: true,
          okText: 'Verify',
        },
        {
          id: 2,
          name: 'assignBoosters',
          text: 'Assign Boosters to This Order',
          notable: false,
          okText: 'Assign',
          reason: true
        },
        {
          id: 3,
          name: 'cancel',
          text: 'By Canceling This Order, Current Booster Will Be Dismissed',
          notable: true,
          okText: 'Confirm',
          reason: true,
        },
        {
          id: 4,
          name: 'Deny',
          text: 'By Denying This Order, Current Booster Will Be Assigned Again And Amount Will Be Reduced From Booster\'s Fund In Hold',
          notable: true,
          okText: 'Confirm',
          reason: true,
        },
      ],
      args: {
        reason: ''
      },

      selectedAction: null
    }
  },
  methods: {
    async verifyOrder() {
      let _this = this
      if (_this.order.boosterId) {
        _this.overlay = true
        let verifyOrder = new VerifyOrder(_this)
        verifyOrder.setParams({
          orderCode: _this.order.orderCode,
          boosterId: _this.order.boosterId
        })
        await verifyOrder.fetch(function (content) {
          _this.overlay = false
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })

            _this.$emit('refetch')
          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.overlay = false
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
        })
      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: 'order has no booster',
            title: `error`,
            variant: 'danger',
          },
        })
      }
    },
    async denyOrder() {
      let _this = this
      if (_this.order.boosterId) {
        _this.overlay = true
        let denyOrder = new DenyOrder(_this)
        denyOrder.setParams({
          orderCode: _this.order.orderCode,
          reason: _this.args.reason
        })
        await denyOrder.fetch(function (content) {
          _this.overlay = false
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })

            _this.$emit('refetch')
            _this.args.reason = ''
          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.overlay = false
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
        })
      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: 'order has no booster',
            title: `error`,
            variant: 'danger',
          },
        })
      }
    },
    async cancel() {
      let _this = this
      if (_this.args.reason) {
        _this.overlay = true
        let orderCancelByAdminRequest = new OrderCancelByAdminRequest(_this)
        orderCancelByAdminRequest.setParams({
          orderCode: _this.order.orderCode,
          reason: _this.args.reason
        })
        await orderCancelByAdminRequest.fetch(function (content) {
          _this.overlay = false
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            _this.args.reason = ''
            _this.$emit('refetch')
          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.overlay = false
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
        })
      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: 'reason is required',
            title: `error`,
            variant: 'danger',
          },
        })
      }
    },
    async assignBoosters() {
      let _this = this
      if (_this.args.reason) {
        _this.overlay = true
        let changeOrderBooster = new ChangeOrderBooster(_this)
        changeOrderBooster.setParams({
          orderCode: _this.order.orderCode,
          boosterId: _this.args['boosterId'],
          reason: _this.args.reason
        })
        await changeOrderBooster.fetch(function (content) {
          _this.overlay = false
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            _this.args.reason = ''

            _this.$emit('refetch')
          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.overlay = false
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
        })
      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: 'reason is required',
            title: `error`,
            variant: 'danger',
          },
        })
      }
    },
    setAction(actionId) {
      let idx = this.actionsData.findIndex(e => e.id === actionId)
      if (idx !== -1) {
        this.selectedAction = this.actionsData[idx]
      }
    },
    setBoosterId(data) {
      this.args['boosterId'] = data
    },
    sendAction() {
      if (this.selectedAction.id === 3) {
        this.cancel()
      } else if (this.selectedAction.id === 2) {
        this.assignBoosters()
      } else if (this.selectedAction.id === 1) {
        this.verifyOrder()
      } else if (this.selectedAction.id === 4) {
        this.denyOrder()
      }
    },
    setEdit() {
      this.$emit('setEdit')
    }
  }
}
</script>

<style scoped>

</style>
